import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GAuth from 'vue3-google-oauth2'
import './index.css'
import 'flowbite'
import 'bootstrap-icons/font/bootstrap-icons.css'
import IndexLayout from '@/components/Layouts/IndexLayout.vue'
import Select2 from 'vue3-select2-component';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'


const app = createApp(App);
//app.component("default-layout", IndexLayout);
app.component(_.join(['Index', 'webpack'], ' '));
app.component("Select2", Select2);
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)

const myLang = {
  dashboard: {
    btn: {
      play: 'Play',
      pause: 'Pause',
      fullscreen: 'Full screen',
      exitFullscreen: 'Exit Full screen',
      mute: 'Mute',
      unmute: 'Unmute',
      back: 'Back',
      pip: 'Picture in Picture',
    },
    settings: {
      autoplay: 'Autoplay',
      loop: 'Loop',
      speed: 'Speed',
      resolution: 'Resolution',
    },
  },
  layers: {
    error: {
      title: 'Error!',
      2404: 'Video Source Undefined',
      2502: 'Media Network Error',
      2503: 'Video Cannot DECODE',
      2504: 'Video Cannot Play!',
      601: 'Live video Cannot Play!',
    },
    loading: {
      msg: 'Loading ...',
    },
  },
};

app.use(Vue3VideoPlayer, {
    lang: myLang
  })

const CLIENT_ID = "866071363558-dqcfltni1ij1s1c8nnb8rhchlbn91fd0.apps.googleusercontent.com";
const API_KEY = "AIzaSyCVMc9roew3y-1l1TOrVR1CzJdcnfzKfUE";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/drive";

// const apiOptions = {
//     apiKey: API_KEY,
//     clientId: CLIENT_ID,
//     discoveryDocs: DISCOVERY_DOCS,
//     scope: SCOPES
// }

const gAuthOptions = { 
    clientId: '866071363558-dqcfltni1ij1s1c8nnb8rhchlbn91fd0.apps.googleusercontent.com',
    callback: 'https://mis.dnsc.edu.ph/akred/',
    plugin_name: 'Sample Web bisag unsa' 
    }

app.use(GAuth, gAuthOptions)
.use(store)
.use(router)
.mount('#app')
