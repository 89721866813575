

<template>
  <footer class="w-full absolute inset-x-0 bottom-0" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="w-full py-3 px-4">
      
      <div class="flex justify-center pr-0 sm:justify-end sm:pr-20">

        <div class="flex items-center my-auto mr-4">
          <router-link v-if="$route.meta.title != 'About'" to="/akred/about" class="mx-auto text-white transition ease-in-out delay-150 hover:scale-110 duration-100">Learn more about DNSC-AKRED </router-link>
          <router-link v-if="$route.meta.title === 'About'" to="/akred/login" class="mx-auto text-white transition ease-in-out delay-150 hover:scale-110 duration-100">Go back</router-link>
        </div>
            <img class="h-10 w-10 sm:h-14 sm:w-14" src="../../assets/dnsc_logo.png" alt="Your Company">
            <img class="h-10 w-10 sm:h-14 sm:w-14" src="../../assets/bagong_pilipinas_logo.png" alt="Your Company">
            <img class="h-10 w-14 sm:h-14 sm:w-auto" src="../../assets/socotec_logo.png" alt="Your Company">
      </div>
      
    
    </div>
  </footer>
</template>


