import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/akred',
    meta: { name: 'Index', parent: true },
    component: () => import('../components/Layouts/IndexLayout.vue'),
    children: [
      {
        path: '/akred/login',
        alias: '/akred',
        component: () => import('../views/LoginView.vue'),
        meta: { title: 'Login', ParentName: 'Index'}
      },
      {
        path: '/akred/about',
        alias: '/akred/about',
        component: () => import('../views/AboutView.vue'),
        meta: { title: 'About', ParentName: 'Index'}
      },
      {
        path: '/akred/signup',
        alias: '/akred/signup',
        component: () => import('../views/SignUpView.vue'),
        meta: { title: 'Sign Up', ParentName: 'Index'}
      },
      {
        path: '/akred/reset_password',
        alias: '/akred/reset_password',
        component: () => import('../views/ResetPasswordView.vue'),
        meta: { title: 'Reset Password', ParentName: 'Index'}
      },
    ],
  },
  {
    path: '/akred/home',
    meta: { name: 'Home', parent: true },
    component: () => import('../components/Layouts/DashboardLayout.vue'),
    children: [
      {
        path: '/akred/dashboard',
        alias: '/akred/dashboard',
        component: () => import('../views/UserHomeView.vue'),
        meta: { title: 'Dashboard', ParentName: 'Home'}
      },
      {
        path: '/akred/module',
        alias: '/akred/module',
        component: () => import('../views/ModuleView.vue'),
        meta: { title: 'Module', ParentName: 'Home'}
      },
      {
        path: '/akred/team_list',
        alias: '/akred/team_list',
        component: () => import('../views/TeamListView.vue'),
        meta: { title: 'Team List', ParentName: 'Home'}
      },
      {
        path: '/akred/notifications',
        alias: '/akred/notifications',
        component: () => import('../views/NotificationsView.vue'),
        meta: { title: 'Notifications', ParentName: 'Home'}
      },
      {
        path: '/akred/accreditations_user',
        alias: '/akred/accreditations_user',
        component: () => import('../views/UserAccreditationView.vue'),
        meta: { title: 'Accreditation User', ParentName: 'Home'}
      },
      {
        path: '/akred/psv',
        alias: '/akred/psv',
        component: () => import('../views/PSVView.vue'),
        meta: { title: 'PSV', ParentName: 'Home'}
      },
      {
        path: '/akred/utility_psv',
        alias: '/akred/utility_psv',
        component: () => import('../views/UtilityPSVView.vue'),
        meta: { title: 'Utility PSV', ParentName: 'Home'}
      },
      {
        path: '/akred/utility_level_1',
        alias: '/akred/utility_level_1',
        component: () => import('../views/UtilityLevel1View.vue'),
        meta: { title: 'Utility Level 1', ParentName: 'Home'}
      },
      {
        path: '/akred/utility_level_2',
        alias: '/akred/utility_level_2',
        component: () => import('../views/UtilityLevel2View.vue'),
        meta: { title: 'Utility Level 2', ParentName: 'Home'}
      },
      {
        path: '/akred/utility_level_3_phase_1',
        alias: '/akred/utility_level_3_phase_1',
        component: () => import('../views/UtilityLevel3Phase1View.vue'),
        meta: { title: 'Utility Level 3 Phase 1', ParentName: 'Home'}
      },
      {
        path: '/akred/utility_level_4',
        alias: '/akred/utility_level_4',
        component: () => import('../views/UtilityLevel4View.vue'),
        meta: { title: 'Utility Level 4', ParentName: 'Home'}
      },
      {
        path: '/akred/level_1',
        alias: '/akred/level_1',
        component: () => import('../views/Level1View.vue'),
        meta: { title: 'Level 1', ParentName: 'Home'}
      },
      {
        path: '/akred/level_2',
        alias: '/akred/level_2',
        component: () => import('../views/Level2View.vue'),
        meta: { title: 'Level 2', ParentName: 'Home'}
      },
      {
        path: '/akred/level_3_phase_1',
        alias: '/akred/level_3_phase_1',
        component: () => import('../views/Level3Phase1View.vue'),
        meta: { title: 'Level 3 Phase 1', ParentName: 'Home'}
      },
      {
        path: '/akred/level_3_phase_2',
        alias: '/akred/level_3_phase_2',
        component: () => import('../views/Level3Phase2View.vue'),
        meta: { title: 'Level 3 Phase 2', ParentName: 'Home'}
      },
      {
        path: '/akred/level_4',
        alias: '/akred/level_4',
        component: () => import('../views/Level4View.vue'),
        meta: { title: 'Level 4', ParentName: 'Home'}
      },
      {
        path: '/akred/shared_files',
        alias: '/akred/shared_files',
        component: () => import('../views/SharedFilesView.vue'),
        meta: { title: 'Shared Files', ParentName: 'Home'}
      },
      {
        path: '/akred/summary_rating',
        alias: '/akred/summary_rating',
        component: () => import('../views/SummaryRatingView.vue'),
        props : { showNotification : Function },
        meta: { title: 'Summary Rating', ParentName: 'Home'}
      },
      {
        path: '/akred/areas',
        alias: '/akred/areas',
        component: () => import('../views/AreasView.vue'),
        meta: { title: 'Areas', ParentName: 'Home'}
      },
      {
        path: '/akred/instruments',
        alias: '/akred/instruments',
        component: () => import('../views/InstrumentsView.vue'),
        meta: { title: 'Instruments', ParentName: 'Home'}
      },
      {
        path: '/akred/institutes',
        alias: '/akred/institutes',
        component: () => import('../views/InstitutesView.vue'),
        meta: { title: 'Institutes', ParentName: 'Home'}
      },
      {
        path: '/akred/programs',
        alias: '/akred/programs',
        component: () => import('../views/ProgramsView.vue'),
        meta: { title: 'Programs', ParentName: 'Home'}
      },
      {
        path: '/akred/offices',
        alias: '/akred/offices',
        component: () => import('../views/OfficesView.vue'),
        meta: { title: 'Offices', ParentName: 'Home'}
      },
      {
        path: '/akred/accreditations',
        alias: '/akred/accreditations',
        component: () => import('../views/AccreditationView.vue'),
        meta: { title: 'Accreditations', ParentName: 'Home'}
      },
      {
        path: '/akred/parameters',
        alias: '/akred/parameters',
        component: () => import('../views/ParametersView.vue'),
        meta: { title: 'Parameters', ParentName: 'Home'}
      },
      {
        path: '/akred/assistant_staff_qa',
        alias: '/akred/assistant_staff_qa',
        component: () => import('../views/AssistantStaffQADashboardView.vue'),
        meta: { title: 'Assistant Staaff QA', ParentName: 'Home'}
      },
      {
        path: '/akred/dean',
        alias: '/akred/dean',
        component: () => import('../views/DeanDashboardView.vue'),
        meta: { title: 'Institute Dean', ParentName: 'Home'}
      },
      {
        path: '/akred/director_qa',
        alias: '/akred/director_qa',
        component: () => import('../views/DirectorQADashboardView.vue'),
        meta: { title: 'Director QA', ParentName: 'Home'}
      },
      {
        path: '/akred/external_accreditor',
        alias: '/akred/external_accreditor',
        component: () => import('../views/ExternalDashboardView.vue'),
        meta: { title: 'External Accreditor', ParentName: 'Home'}
      },
      {
        path: '/akred/internal_accreditor',
        alias: '/akred/internal_accreditor',
        component: () => import('../views/InternalDashboardView.vue'),
        meta: { title: 'Internal Accreditor', ParentName: 'Home'}
      },
      {
        path: '/akred/head_director',
        alias: '/akred/head_director',
        component: () => import('../views/HeadDirectorDashboardView.vue'),
        meta: { title: 'Head Director', ParentName: 'Home'}
      },
      {
        path: '/akred/program_chair',
        alias: '/akred/program_chair',
        component: () => import('../views/ProgramChairDashboardView.vue'),
        meta: { title: 'Program Chair', ParentName: 'Home'}
      },
      {
        path: '/akred/task_force',
        alias: '/akred/task_force',
        component: () => import('../views/TaskForceDashboardView.vue'),
        meta: { title: 'Task Force', ParentName: 'Home'}
      },
      {
        path: '/akred/accounts',
        alias: '/akred/accounts',
        component: () => import('../views/AccountsView.vue'),
        meta: { title: 'Accounts', ParentName: 'Home'}
      },
      {
        path: '/akred/profile',
        alias: '/akred/profile',
        component: () => import('../views/ProfileView.vue'),
        meta: { title: 'Profile', ParentName: 'Home'}
      },
      {
        path: '/akred/settings',
        alias: '/akred/settings',
        component: () => import('../views/SettingsView.vue'),
        meta: { title: 'Settings', ParentName: 'Home'}
      },
      {
        path: '/akred/announcements',
        alias: '/akred/announcements',
        component: () => import('../views/AnnouncementsView.vue'),
        meta: { title: 'Announcements', ParentName: 'Home'}
      },
      {
        path: '/akred/trash',
        alias: '/akred/trash',
        component: () => import('../views/TrashView.vue'),
        meta: { title: 'Trash', ParentName: 'Home'}
      },
    ]
  }
  
]
const DEFAULT_TITLE = 'AKRED';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router.afterEach((to) => {
//   nextTick(() => {
//     if( to.meta && to.meta.title != ''   ) document.title = "AKRED - " + to.meta.title;
//     else document.title = DEFAULT_TITLE; 
//   });
// });
router.beforeEach((to, from, next) => {
  let role = store.state.user;
  if (to.matched.some(record => record.meta.Home)) {
      if (!role) {
          next({ path: '/akred'})
      } else {
        document.title = "AKRED - " + to.meta.title;
        next()
      }
  }
  else {
    document.title = "AKRED - " + to.meta.title;
      next()
  }
})

export default router

