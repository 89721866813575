<template>
    <div class="bodyTemp flex flex-col sm:h-screen justify-between">

      <router-view></router-view>
        
        <IndexFooter/>
    </div>
  </template>
  
  <script>
  
    import IndexFooter from "../Footers/IndexFooter.vue";
  
    export default{
      components: {
        IndexFooter
      }
    }
  
  </script>

  <style scoped>

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

    .bodyTemp{
      background: url('../../assets/index_bg.png') no-repeat;
        background-size: cover;
        font-family: "Inter";
    }



  </style>
